import concatURL from "./concatURL";
import { pickBy as _pickBy, identity as _identity } from "lodash";

/**
 * @typedef {object} ArticleObjectParams
 * @prop {string} [type]
 * @prop {string} id
 * @prop {string} title
 * @prop {string} datePublished
 * @prop {string} dateModified
 * @prop {string} inLanguage
 * @prop {string} articleSection
 * @prop {object} [publisher]
 * @prop {object} [webPage]
 * @prop {object} [author]
 * @prop {object} [image]
 *
 * @method
 * @param {ArticleObjectParams} props
 *
 * @returns {object}
 */
export function createArticleObject({
  type,
  id,
  webPage,
  author,
  title,
  datePublished,
  dateModified,
  publisher,
  image,
  inLanguage,
  articleSection,
}) {
  return _pickBy(
    {
      "@type": type || "Article",
      "@id": id,
      isPartOf: {
        "@id": webPage["@id"],
      },
      author: {
        "@id": author["@id"],
      },
      headline: title,
      datePublished,
      dateModified,
      mainEntityOfPage: {
        "@id": webPage["@id"],
      },
      publisher: { "@id": publisher["@id"] },
      image: image && {
        "@id": image["@id"],
      },
      articleSection,
      inLanguage,
    },
    _identity
  );
}

/**
 * @typedef {object} OrganizationObjectParams
 * @prop {string} id
 * @prop {string} name
 * @prop {string} [description]
 * @prop {string} url
 * @prop {string} [location]
 * @prop {object} [logo]
 * @prop {object} [subjectOf]
 * @prop {{ url: string; }[]} [social]
 *
 * @method
 * @param {OrganizationObjectParams} props
 *
 * @returns {object}
 */
export function createOrganizationObject({
  id,
  name,
  url,
  logo,
  social,
  description,
  location,
  subjectOf,
}) {
  return _pickBy(
    {
      "@type": "Organization",
      "@id": id,
      name: name,
      url,
      sameAs: social ? [...social.map(({ url }) => url)] : undefined,
      logo: logo,
      image: logo && { "@id": logo["@id"] },
      description,
      location,
      subjectOf: subjectOf && { "@id": subjectOf["@id"] },
    },
    _identity
  );
}

/**
 * @typedef {object} ImageObjectParams
 * @prop {string} id
 * @prop {string} inLanguage
 * @prop {?SEO.SEOImage} image
 *
 * @method
 * @param {ImageObjectParams} props
 *
 * @returns {object}
 */
export function createImageObject({ id, inLanguage, image }) {
  if (!image) {
    return null;
  }

  const url = image.secureUrl || image.url;
  const optionalProps = _pickBy(
    {
      width: image.width,
      height: image.height,
      caption: image.caption || image.alt,
    },
    _identity
  );

  return {
    "@type": "ImageObject",
    "@id": id,
    inLanguage,
    url,
    ...optionalProps,
  };
}

/**
 * @typedef {object} StructuredDataParams
 * @prop {string} siteUrl
 * @prop {string} pathname
 *
 * @prop {string} inLanguage
 * @prop {SEO.SEOImage} logoImage
 * @prop {SEO.SEOImage} primaryImage
 * @prop {string} organizationName
 * @prop {string} [organizationDescription]
 * @prop {SEO.SocialLink[]} [social]
 * @prop {string} websiteName
 * @prop {string} siteDescription
 * @prop {string} pageDescription
 * @prop {string} pageTitle
 * @prop {SEO.StructuredDataCreator} createOtherStructuredData
 * @prop {string} datePublished
 * @prop {string} dateModified
 *
 * @prop {Boolean} [isArticle]
 * @prop {SEO.Author} [author]
 * @prop {string} [articleType]
 * @prop {string} [articleSection]
 *
 * @method
 * @param {StructuredDataParams} props
 *
 * @returns {object}
 */
export default function createStructuredDataObject({
  articleType,
  siteUrl,
  pathname,
  inLanguage,
  organizationName,
  organizationDescription,
  social = [],
  websiteName,
  siteDescription,
  pageDescription,
  pageTitle,
  logoImage,
  primaryImage,
  createOtherStructuredData,
  datePublished,
  dateModified,
  isArticle,
  author,
  articleSection = "Blog",
}) {
  const logo = createImageObject({
    id: concatURL(siteUrl, "#logo"),
    inLanguage,
    image: logoImage,
  });

  const organization = createOrganizationObject({
    id: concatURL(siteUrl, "#organization"),
    name: organizationName,
    url: siteUrl,
    social,
    logo,
    description: organizationDescription,
  });

  const website = {
    "@type": "WebSite",
    "@id": concatURL(siteUrl, "#website"),
    url: siteUrl,
    name: websiteName,
    inLanguage,
    description: siteDescription,
    publisher: { "@id": organization["@id"] },
    // potentialAction: {
    // "@type": "SearchAction",
    // target: "https://inex.one/?s={search_term_string}",
    // "query-input": "required name=search_term_string"
    // },
  };

  const primaryImageData = createImageObject({
    id: concatURL(siteUrl, pathname, "#primaryimage"),
    inLanguage,
    image: primaryImage,
  });

  const webPage = _pickBy(
    {
      "@type": "WebPage",
      "@id": concatURL(siteUrl, pathname, "#webpage"),
      url: concatURL(siteUrl, pathname),
      name: pageTitle,
      isPartOf: { "@id": website["@id"] },
      inLanguage,
      primaryImageOfPage: primaryImageData
        ? {
            "@id": primaryImageData["@id"],
          }
        : undefined,
      datePublished,
      dateModified,
      description: pageDescription,
    },
    _identity
  );

  const authorData =
    isArticle && author
      ? _pickBy(
          {
            "@type": ["Person"],
            "@id": concatURL(
              siteUrl,
              "/person/",
              author.email.replace(/[@.]/g, "_")
            ),
            name: author.name,
            image: createImageObject({
              id: concatURL(siteUrl, pathname, "#authorAvatar"),
              inLanguage,
              image: author.avatar,
            }),
            description: author.description,
            sameAs: author.social.map(({ url }) => url),
          },
          _identity
        )
      : null;

  const article = isArticle
    ? createArticleObject({
        type: articleType,
        id: concatURL(siteUrl, pathname, "#article"),
        webPage,
        author: authorData || organization,
        title: pageTitle,
        dateModified,
        datePublished,
        publisher: organization,
        image: primaryImageData,
        articleSection,
        inLanguage,
      })
    : null;

  return {
    "@context": "https://schema.org",
    "@graph": [
      organization,
      website,
      primaryImageData,
      webPage,
      authorData,
      article,
      ...createOtherStructuredData({
        organization,
        website,
        primaryImageData,
        webPage,
        authorData,
        article,
        inLanguage,
        siteUrl,
      }).map((sdItem) => _pickBy(sdItem, _identity)),
    ].filter((obj) => Boolean(obj)),
  };
}
