import { useStaticQuery, graphql } from "gatsby";

export default function useHeaderConfig() {
  /** @type {GatsbyTypes.HeaderConfigQuery} */
  const data = useStaticQuery(graphql`
    query HeaderConfig {
      markdownRemark(frontmatter: { identifier: { eq: "header" } }) {
        frontmatter {
          headerLogo {
            image {
              extension
              publicURL
            }
            alt
            caption
          }
          headerMenu {
            menuItems {
              title
              type
              url
              subMenuItems {
                title
                type
                url
              }
            }
            buttons {
              title
              type
              url
            }
          }
        }
      }
    }
  `);
  return data.markdownRemark.frontmatter;
}
