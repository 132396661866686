import concatURL from "./concatURL";

/**
 * @typedef {object} CreateSEOImageOptions
 * @prop {SEO.QueryImage} [fallbackImage]
 * @prop {string} baseUrl
 *
 * @method
 * @param {SEO.QueryImage} image
 * @param {CreateSEOImageOptions} options
 *
 * @returns {?SEO.SEOImage}
 */
export function createSEOImage(image, { fallbackImage, baseUrl }) {
  if (!image || !image.publicURL) {
    if (!fallbackImage) return null;
    return createSEOImage(fallbackImage, { baseUrl });
  }

  const { src, width, height, alt, caption, publicURL } = image;

  if (src) {
    const url = concatURL(baseUrl, src);
    return {
      url,
      secureUrl: url.indexOf("https") > -1 ? url : undefined,
      width: `${width}`,
      height: `${height}`,
      alt,
      caption,
    };
  }

  const url = concatURL(baseUrl, publicURL);
  return {
    url,
    secureUrl: url.indexOf("https") > -1 ? url : undefined,
    alt,
    caption,
  };
}

/**
 * @typedef {object} MetaTags
 * @prop {string} [name]
 * @prop {string} [property]
 * @prop {string} content
 *
 * @method
 * @param {SEO.QueryImage} image
 * @param {CreateSEOImageOptions} options
 *
 * @returns {MetaTags[]}
 */
export function createOpenGraphImageTags(image, { fallbackImage, baseUrl }) {
  const openGraphImage = createSEOImage(image, { fallbackImage, baseUrl });

  if (!openGraphImage) return [];

  const optionalTags = [
    {
      property: "og:image:url",
      content: openGraphImage.url,
    },
    { property: "og:image:secure_url", value: openGraphImage.secureUrl },
    {
      property: "og:image:width",
      value: openGraphImage.width,
    },
    {
      property: "og:image:height",
      value: openGraphImage.height,
    },
    {
      property: "og:image:alt",
      value: openGraphImage.alt || openGraphImage.caption,
    },
  ]
    .filter(({ value }) => Boolean(value))
    .map(({ property, value }) => ({
      property,
      content: value,
    }));

  return [
    {
      name: "image",
      property: "og:image",
      content: openGraphImage.url,
    },
    ...optionalTags,
  ];
}

/**
 * @method
 * @param {SEO.QueryImage} image
 * @param {CreateSEOImageOptions} options
 *
 * @returns {MetaTags[]}
 */
export function createTwitterImageTags(image, { fallbackImage, baseUrl }) {
  const twitterImage = createSEOImage(image, { fallbackImage, baseUrl });

  if (!twitterImage) return [];

  const optionalTags = [
    { name: "twitter:image:secure_url", value: twitterImage.secureUrl },
    {
      name: "twitter:image:alt",
      value: twitterImage.alt || twitterImage.caption,
    },
  ]
    .filter(({ value }) => Boolean(value))
    .map(({ name, value }) => ({
      name,
      content: value,
    }));

  return [
    {
      name: "twitter:image",
      content: twitterImage.url,
    },
    ...optionalTags,
  ];
}
