import React from "react";

import { Helmet } from "react-helmet";
import useSEOConfig from "../../hooks/useSEOConfig";
import useSiteMetadata from "../../hooks/useSiteMetadata";

import concatURL from "./utils/concatURL";
import {
  createOpenGraphImageTags,
  createTwitterImageTags,
  createSEOImage,
} from "./utils/createSEOImage";
import createStructuredDataObject from "./utils/createStructuredDataObject";
import createQueryImageFromFile from "./utils/createQueryImageFromFile";
import dayjs from "dayjs";

/**
 * @method
 * @param {string} str
 * @param {object} [options]
 * @param {string} [options.template]
 * @param {number} [options.length]
 *
 * @returns {object}
 */
function trimString(str, { template, length = 60 } = {}) {
  let result = template ? template.replace("%s", str) : str;
  if (result.length > length) {
    result = template
      ? template.replace(
          "%s",
          `${str.substring(0, str.length - 3 - (result.length - length))}...`
        )
      : `${str.substring(0, length - 3)}...`;
  }

  return result;
}

/**
 * @typedef {object} ImagesProp
 * @prop {SEO.QueryImage} [openGraph]
 * @prop {SEO.QueryImage} [twitter]
 * @prop {SEO.QueryImage} [sdPrimaryImage]
 *
 * @typedef {object} SEOProps
 * @prop {string} [title]
 * @prop {string} [description]
 * @prop {string} [lang]
 * @prop {object[]} [meta]
 * @prop {string} [canonicalUrl]
 * @prop {string} pathname
 * @prop {ImagesProp} [images]
 *
 * @prop {Boolean} [isArticle]
 * @prop {string} [articleType]
 * @prop {SEO.AuthorFromQuery} [author]
 * @prop {readonly string[]} [postTags]
 * @prop {Date} datePublished
 * @prop {Date} dateModified
 *
 * @prop {SEO.StructuredDataCreator} [createOtherStructuredData]
 *
 * @param {React.PropsWithChildren<SEOProps>} props
 */
export default function SEO({
  pathname,
  articleType,
  isArticle,
  postTags,
  datePublished,
  dateModified,
  title,
  description,
  images: { openGraph, twitter, sdPrimaryImage } = {},
  author,
  lang = `en-US`,
  canonicalUrl,
  createOtherStructuredData = () => [],
  children,
}) {
  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    defaultAuthor,
    defaultImage,
    logoImage: logoImageFile,
    social,
  } = useSEOConfig();
  const { siteUrl } = useSiteMetadata();

  const titleText =
    (title && trimString(title, { template: titleTemplate })) || defaultTitle;
  const descriptionText = description
    ? trimString(description, { length: 160 })
    : defaultDescription;
  const url = canonicalUrl || concatURL(siteUrl, pathname);

  /**
   * @type {SEO.SocialLink[]}
   */
  const socialLinks = social.map(({ title, type, url, accountHandle }) => ({
    title,
    type,
    url,
    accountHandle,
  }));
  const twitterSocial = socialLinks.find(({ type }) => type === "twitter");
  const twitterHandle = twitterSocial ? twitterSocial.accountHandle : undefined;
  const twitterHandleTags = twitterHandle
    ? [
        {
          name: `twitter:creator`,
          content: twitterHandle,
        },
        {
          name: `twitter:site`,
          content: twitterHandle,
        },
      ]
    : [];

  const publishDate = dayjs(datePublished);
  const modifyDate = dayjs(dateModified);

  const publishDateString = publishDate.isValid()
    ? publishDate.toISOString().split(".")[0] + "Z"
    : null;
  const modifyDateString = modifyDate.isValid()
    ? dayjs(dateModified).toISOString().split(".")[0] + "Z"
    : null;
  const articleSection = isArticle ? postTags.join(", ") : null;
  const articleTags = isArticle
    ? [
        {
          name: "article:published_time",
          content: publishDateString,
        },
        {
          name: "article:modified_time",
          content: modifyDateString,
        },
        {
          name: "article:section",
          content: articleSection,
        },
        ...social.map(({ url }) => ({
          name: "article:publisher",
          content: url,
        })),
      ].filter(({ content }) => Boolean(content))
    : [];

  const [
    defaultOgImage,
    defaultTwitterImage,
    defaultSdPrimaryImage,
  ] = createQueryImageFromFile(defaultImage, [
    "openGraphImage.resize",
    "twitterImage.resize",
    "sdImage.resize",
  ]);
  const openGraphImageTags = createOpenGraphImageTags(openGraph, {
    fallbackImage: defaultOgImage,
    baseUrl: siteUrl,
  });
  const twitterImageTags = createTwitterImageTags(twitter, {
    fallbackImage: defaultTwitterImage,
    baseUrl: siteUrl,
  });
  const primaryImage = createSEOImage(sdPrimaryImage, {
    fallbackImage: defaultSdPrimaryImage,
    baseUrl: siteUrl,
  });

  const [logoImage] = createQueryImageFromFile(logoImageFile, ["sdImage"]);
  const sdLogoImage = createSEOImage(logoImage, {
    baseUrl: siteUrl,
  });

  return (
    <Helmet
      titleTemplate={titleTemplate}
      defaultTitle={defaultTitle}
      title={trimString(title)}
      htmlAttributes={{
        lang,
      }}
      meta={[
        {
          name: "description",
          content: descriptionText,
        },
        {
          name: "author",
          content: (author && author.name) || defaultAuthor,
        },
        {
          name: "robots",
          content:
            "max-snippet:-1, max-image-preview:large, max-video-preview:-1",
        },

        // Open Graph tags
        {
          name: "publish_date",
          property: "og:publish_date",
          content: publishDateString,
        },
        {
          name: "modify_date",
          property: "og:modify_date",
          content: modifyDateString,
        },
        {
          property: "og:title",
          content: titleText,
        },
        {
          property: "og:type",
          content: isArticle ? "article" : "website",
        },
        {
          property: "og:description",
          content: descriptionText,
        },
        {
          property: "og:locale",
          content: "en_US",
        },
        {
          property: "og:url",
          content: url,
        },
        {
          property: "og:site_name",
          content: defaultTitle,
        },
        ...openGraphImageTags,

        // Twitter tags
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:title`,
          content: titleText,
        },
        {
          name: `twitter:description`,
          content: descriptionText,
        },
        ...twitterHandleTags,
        ...twitterImageTags,
        ...articleTags,
      ]}
      link={[
        {
          rel: "canonical",
          href: url,
        },
      ]}
    >
      {children}
      {/* Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify(
          createStructuredDataObject({
            articleType,
            isArticle,
            siteUrl,
            pathname,
            inLanguage: lang,
            organizationName: defaultAuthor,
            websiteName: defaultTitle,
            siteDescription: defaultDescription,
            pageDescription: description,
            pageTitle: title || defaultTitle,
            primaryImage,
            logoImage: sdLogoImage,
            articleSection,
            datePublished: publishDateString,
            dateModified: modifyDateString,
            social: socialLinks,
            author: author && {
              ...author,
              avatar: createSEOImage(author.avatar, {
                baseUrl: siteUrl,
              }),
            },
            createOtherStructuredData,
          })
        )}
      </script>
    </Helmet>
  );
}
