import React from "react";
import { Divider } from "antd";
import {
  FacebookFilled,
  TwitterSquareFilled,
  LinkedinFilled,
  InstagramFilled,
} from "@ant-design/icons";

import { Link } from "utils";
import { SOCIAL_LINK_TYPES } from "shared/constants";
import Image from "components/Image/";

import useFooterConfig from "hooks/useFooterConfig";
import useSiteMetadata from "hooks/useSiteMetadata";
import useSEOConfig from "hooks/useSEOConfig";

import styles from "./Footer.module.less";

// TODO: Add typings
function SocialIcon({ type, title }) {
  switch (type) {
    case SOCIAL_LINK_TYPES.FACEBOOK:
      return <FacebookFilled />;
    case SOCIAL_LINK_TYPES.TWITTER:
      return <TwitterSquareFilled />;
    case SOCIAL_LINK_TYPES.LINKEDIN:
      return <LinkedinFilled />;
    case SOCIAL_LINK_TYPES.INSTAGRAM:
      return <InstagramFilled />;
    default:
      return title;
  }
}

/** @param {GatsbyTypes.FooterConfigQuery["markdownRemark"]["frontmatter"]["footerMenuLeftColumn"]} props */
function FooterMenu({ title: menuTitle, menuItems }) {
  return (
    <section className={styles.footerMenu}>
      <h3 className={styles.footerHeader}>{menuTitle}</h3>
      <ul>
        {menuItems.map(({ title, url }, index) => (
          <li key={`${menuTitle}-${index}`}>
            <Link to={url}>{title}</Link>
          </li>
        ))}
      </ul>
    </section>
  );
}

export default function Footer() {
  const {
    footerLogo,
    footerMenuLeftColumn,
    footerMenuRightColumn,
  } = useFooterConfig();

  const { copyrightMessage } = useSiteMetadata();
  const { social } = useSEOConfig();

  const publicURL = footerLogo.image ? footerLogo.image.publicURL : null;

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.navContainer}>
          <div>
            <Link to={"/"}>
              <Image
                className={styles.footerLogo}
                publicURL={publicURL}
                alt={footerLogo.alt}
              />
            </Link>
          </div>
          <nav>
            <FooterMenu {...footerMenuLeftColumn} />
            <FooterMenu {...footerMenuRightColumn} />
          </nav>
        </div>
        <Divider className={styles.divider} />
        <div className={styles.miscContainer}>
          <small>
            &copy;{` ${new Date().getFullYear()} ${copyrightMessage}`}
          </small>
          <ul>
            <h3 className={styles.footerHeader}>Connect with us</h3>
            {social.map(({ title, type, url }, index) => (
              <li key={`${title}-${index}`}>
                <Link to={url}>
                  <SocialIcon title={title} type={type} />
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
}
