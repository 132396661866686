import { get as _get } from "lodash";

/**
 * @typedef ImageParam
 * @prop {GatsbyTypes.FileInformationFragment} image
 * @prop {string} alt
 * @prop {string} caption
 *
 * @method
 * @param {ImageParam} queryImage
 * @param {string[]} imagePropNames
 *
 * @returns {?SEO.QueryImage[]}
 */
export default function createQueryImageFromFile(queryImage, imagePropNames) {
  if (!queryImage || !queryImage.image) return [];

  const imageFileProps = {
    extension: queryImage.image.extension,
    publicURL: queryImage.image.publicURL,
    alt: queryImage.alt,
    caption: queryImage.caption,
  };

  return imagePropNames.map((propName) => {
    const imageProps = _get(queryImage.image, propName);
    if (!imageProps) {
      return {};
    }

    return {
      ...imageProps,
      ...imageFileProps,
    };
  });
}
