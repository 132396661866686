import React, { useEffect, useState, useCallback } from "react";
import { classNames } from "utils";

import Header from "components/Header";
import Footer from "components/Footer";

import styles from "./Layout.module.less";

const isTopVerticalScroll = () =>
  (window.pageYOffset || document.documentElement.scrollTop) === 0;

/**
 *
 * @param {React.PropsWithChildren<{ gradientBackground?: boolean;}>} props
 */
export default function Layout({ children, gradientBackground }) {
  const [isHeaderCollapsed, setCollapsedHeader] = useState(false);

  // useCallback ensures the reference to `handleWindowScroll` never changes, even after a render
  const handleWindowScroll = useCallback(
    () =>
      // Doesn't trigger a re-render if the states are shallow equal
      setCollapsedHeader(!isTopVerticalScroll()),
    // Keeps this equal throughout any layout render
    []
  );

  /** Check on initial render. SSR prevents from initializing the value from window */
  useEffect(() => handleWindowScroll(), [handleWindowScroll]);

  useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleWindowScroll);
    };
    /** Don't clean-up/subscribe this every time the component re-renders */
  }, [handleWindowScroll]);

  return (
    <React.Fragment>
      <Header isCollapsed={isHeaderCollapsed} />
      <main
        className={classNames(styles.main, {
          [styles.gradientBackground]: gradientBackground,
        })}
      >
        {children}
      </main>
      <Footer />
    </React.Fragment>
  );
}
