import { useStaticQuery, graphql } from "gatsby";

export default function useFooterConfig() {
  /** @type {GatsbyTypes.FooterConfigQuery} */
  const data = useStaticQuery(graphql`
    query FooterConfig {
      markdownRemark(frontmatter: { identifier: { eq: "footer" } }) {
        frontmatter {
          footerLogo {
            image {
              # No sharp to be used with an svg
              extension
              publicURL
            }
            alt
            caption
          }
          footerMenuLeftColumn {
            title
            menuItems {
              title
              url
              type
            }
          }
          footerMenuRightColumn {
            title
            menuItems {
              title
              url
              type
            }
          }
        }
      }
    }
  `);
  return data.markdownRemark.frontmatter;
}
