import SEO from "./SEO";
import createQueryImageFunc from "./utils/createQueryImageFromFile";
import concatURLFunc from "./utils/concatURL";
import {
  createImageObject,
  createOrganizationObject,
  createArticleObject,
} from "./utils/createStructuredDataObject";
import * as createSEOImageFuncs from "./utils/createSEOImage";

export default SEO;
export const createQueryImageFromFile = createQueryImageFunc;
export const SEOImage = createSEOImageFuncs;
export const StructuredData = {
  createImageObject,
  createOrganizationObject,
  createArticleObject,
};
export const concatURL = concatURLFunc;
