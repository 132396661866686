import { useStaticQuery, graphql } from "gatsby";

/**
 * Source for width values: https://iamturns.com/open-graph-image-size/
 */
export default function useSEOConfig() {
  /** @type {GatsbyTypes.SEOConfigQuery} */
  const data = useStaticQuery(graphql`
    query SEOConfig {
      markdownRemark(frontmatter: { identifier: { eq: "seo" } }) {
        frontmatter {
          titleTemplate
          defaultTitle
          defaultDescription
          defaultAuthor
          defaultImage {
            image {
              ...OpenGraphImage
              ...TwitterImage
              ...StructuredDataImage
            }
            alt
            caption
          }
          logoImage {
            image {
              ...StructuredDataImage
            }
            alt
            caption
          }
          social {
            type
            title
            url
            accountHandle
          }
        }
      }
    }
  `);
  return data.markdownRemark.frontmatter;
}
