import React, { useState } from "react";
import { classNames } from "utils";
import { Drawer, Menu } from "antd";
import { Link } from "utils";

import useHeaderConfig from "hooks/useHeaderConfig";
import getIcon from "components/Icons";
import { PrimaryButton, SecondaryButton } from "components/Buttons";
import Image from "components/Image/";
import { MENU_ITEM_TYPES } from "shared/constants";

import styles from "./Header.module.less";

const { SubMenu } = Menu;

const MobileBarClose = ({ setSideBarOpen }) => {
  return (
    <div className={styles.mobileCloseContainer}>
      <SecondaryButton
        title={null}
        isIconOnly={true}
        icon={getIcon("close")}
        onClick={() => setSideBarOpen(false)}
        className={styles.mobileNavButton}
      />
    </div>
  );
};

function HeaderButton({ title, type, to }) {
  switch (type) {
    case "default":
      return <PrimaryButton to={to} isGhost title={title} />;
    case "primary":
      return <PrimaryButton to={to} title={title} />;
    default:
      return <PrimaryButton to={to} title={title} />;
  }
}

// TODO: Add typings
// /**
//  * @param {CollectionTypes.MenuItem} MenuItem
//  * @param {Number} index
//  *
//  */
export default function Header({ isCollapsed = false }) {
  const {
    headerLogo,
    headerMenu: { buttons, menuItems },
  } = useHeaderConfig();
  const [sideBarOpen, setSideBarOpen] = useState(false);

  const publicURL = headerLogo.image ? headerLogo.image.publicURL : null;

  return (
    <header
      className={classNames(styles.header, {
        [styles.collapsedHeader]: isCollapsed,
      })}
    >
      <div className={styles.headerContainer}>
        <div className={styles.logoContainer}>
          <Link to={"/"}>
            <Image
              className={styles.headerLogo}
              publicURL={publicURL}
              alt={headerLogo.alt}
            />
          </Link>
        </div>
        <nav className={styles.nav}>
          <Menu className={styles.menu} mode="horizontal" selectable={false}>
            {menuItems.map(({ title, url, type, subMenuItems }, index) => {
              switch (type) {
                case MENU_ITEM_TYPES.LINK:
                  return (
                    <Menu.Item key={`MenuItem-${index}`}>
                      <Link to={url}>{title}</Link>
                    </Menu.Item>
                  );
                case MENU_ITEM_TYPES.SUBMENU:
                  return (
                    <SubMenu
                      key={`MenuItem-${index}`}
                      popupClassName={styles.subMenu}
                      title={
                        <div className={styles.subMenuTitle}>
                          {title}
                          {getIcon("chevronDown", { width: 10 })}
                        </div>
                      }
                    >
                      {subMenuItems.map(({ url, title }, subindex) => (
                        <Menu.Item key={`MenuItem-${index}-${subindex}`}>
                          <Link to={url}>{title}</Link>
                        </Menu.Item>
                      ))}
                    </SubMenu>
                  );

                default:
                  return (
                    <Menu.Item key={`MenuItem-${index}`}>{title}</Menu.Item>
                  );
              }
            })}
          </Menu>
          <div className={styles.buttonsContainer}>
            {buttons.map(({ title, type, url }, index) => (
              <HeaderButton
                key={`HeaderButton-${index}`}
                to={url}
                title={title}
                type={type}
              />
            ))}
          </div>

          {/* Mobile elements */}
          <PrimaryButton
            title={null}
            isIconOnly={true}
            icon={getIcon("menu")}
            onClick={() => setSideBarOpen(true)}
            className={styles.mobileNavButton}
          />
          <Drawer
            placement="right"
            width="20rem"
            className={styles.mobileSideBar}
            closable={false}
            onClose={() => setSideBarOpen(false)}
            visible={sideBarOpen}
          >
            <div className={styles.mobileNav}>
              <MobileBarClose setSideBarOpen={setSideBarOpen} />
              <div className={styles.mobileMenu}>
                {menuItems.map(({ title, url, type, subMenuItems }, index) => {
                  switch (type) {
                    case MENU_ITEM_TYPES.LINK:
                      return (
                        <Link key={`MenuItem-${index}`} to={url}>
                          {title}
                        </Link>
                      );
                    case MENU_ITEM_TYPES.SUBMENU:
                      return (
                        <React.Fragment key={`MenuItem-${index}`}>
                          <h1>{title}</h1>
                          {subMenuItems.map(({ url, title }, index) => (
                            <Link key={`SubMenuItem-${index}`} to={url}>
                              {title}
                            </Link>
                          ))}
                        </React.Fragment>
                      );

                    default:
                      return <div key={`MenuItem-${index}`}>{title}</div>;
                  }
                })}
              </div>
            </div>
            <div className={styles.mobileButtonContainer}>
              {buttons.map(({ title, url }, index) => (
                <SecondaryButton
                  key={`MenuButton-${index}`}
                  to={url}
                  title={title}
                />
              ))}
            </div>
          </Drawer>
        </nav>
      </div>
    </header>
  );
}
