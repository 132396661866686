export const COLOR_GRADIENT = `linear-gradient(180deg, #FFFFFF 0%, #eef5fd 100%)`;

/**
 * The types of a Transitioning cube positions
 * @enum {string}
 */
export const CUBE_POSITIONS = {
  RIGHT_OF_CONTENT: "Right of content",
  LEFT_OF_CONTENT: "Left of content",
  RIGHT_OF_ILLUSTRATION: "Right of illustration",
};

/**
 * The types of a menu item
 * @enum {string}
 */
export const MENU_ITEM_TYPES = {
  /** This type is used to represent a simple link menu item. */
  LINK: "link",
  /** This type is used to represent a button menu item. */
  BUTTON: "button",
  /** This type is used to represent a submenu item. */
  SUBMENU: "submenu",
};

/**
 * The types of a buttons
 * @enum {string}
 */
export const BUTTON_TYPES = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  LINK: "link",
};

/**
 * The types of a social link
 * @enum {string}
 */
export const SOCIAL_LINK_TYPES = {
  /** This type is used to represent a Facebook link. */
  FACEBOOK: "facebook",
  /** This type is used to represent a Twitter link. */
  TWITTER: "twitter",
  /** This type is used to represent a LinkedIn link. */
  LINKEDIN: "linkedin",
  /** This type is used to represent a Instagram link. */
  INSTAGRAM: "instagram",
};

/**
 * The types of Sections
 * @enum {string}
 */
export const SECTION_TYPES = {
  FEATURES: "section_features",
  BLOG_SUGGESTIONS: "section_blog_suggestions",
  CLIENT_CASES: "section_client_cases",
  TESTIMONIALS: "section_testimonials",
  CALL_TO_ACTION: "section_cta",
  CONTENT: "section_content",
  HERO: "section_hero",
  LEADERSHIP: "section_leadership",
  CONTENT_CARDS: "section_content_cards",
  COLLAPSIBLE: "section_collapsible",
  EBOOKS: "section_ebooks",
};
