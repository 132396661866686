import React from "react";
import { Link } from "utils";
import { Button } from "antd";
import { classNames } from "utils";

import styles from "./Buttons.module.less";

/**
 * @typedef {Object} AdditionalPrimaryButtonProps
 * @prop {string} title
 * @prop {string} [className]
 * @prop {Boolean} [isContent]
 * @prop {Boolean} [isIconOnly]
 * @prop {Boolean} [hasBoxShadow]
 * @prop {Boolean} [fullWidth]
 * @prop {Boolean} [isGhost]
 * @prop {React.ReactNode} [icon]
 * @prop {string} [to]
 *
 * @param {import("antd/lib/button").ButtonProps & AdditionalPrimaryButtonProps} props
 */
export function PrimaryButton({
  isContent = false,
  isGhost = false,
  isIconOnly = false,
  hasBoxShadow = false,
  title,
  className: customClassName,
  icon,
  to,
  fullWidth,
  ...props
}) {
  const button = (
    <Button
      {...props}
      type="primary"
      ghost={isGhost}
      className={classNames(styles.button, {
        [styles.contentButton]: isContent,
        [styles.hasBoxShadow]: hasBoxShadow,
        [styles.fullWidthButton]: fullWidth,
        [customClassName]: !Boolean(to),
      })}
    >
      <div
        className={classNames(styles.textWithIconContainer, {
          [styles.isIconOnly]: isIconOnly,
        })}
      >
        {title}
        {icon}
      </div>
    </Button>
  );

  if (to) {
    return (
      <Link
        to={to}
        className={classNames({
          [customClassName]: Boolean(to),
          [styles.fullWidthButton]: fullWidth,
        })}
      >
        {button}
      </Link>
    );
  }
  return button;
}

/**
 * @typedef {Object} AdditionalSecondaryButtonProps
 * @prop {string} title
 * @prop {string} [className]
 * @prop {Boolean} [isContent]
 * @prop {Boolean} [isIconOnly]
 * @prop {Boolean} [hasBoxShadow]
 * @prop {Boolean} [fullWidth]
 * @prop {React.ReactNode} [icon]
 * @prop {string} [to]
 *
 * @param {import("antd/lib/button").ButtonProps & AdditionalSecondaryButtonProps} props
 */
export function SecondaryButton({
  isContent = false,
  isIconOnly = false,
  hasBoxShadow = false,
  icon,
  title,
  className: customClassName,
  to,
  fullWidth,
  ...props
}) {
  const button = (
    <Button
      {...props}
      type="primary"
      className={classNames(styles.button, styles.secondaryButton, {
        [styles.contentButton]: isContent,
        [styles.hasBoxShadow]: hasBoxShadow,
        [styles.fullWidthButton]: fullWidth,
        [customClassName]: !Boolean(to),
      })}
    >
      <div
        className={classNames(styles.textWithIconContainer, {
          [styles.isIconOnly]: isIconOnly,
        })}
      >
        {title}
        {icon}
      </div>
    </Button>
  );

  if (to) {
    return (
      <Link
        to={to}
        className={classNames({
          [customClassName]: Boolean(to),
          [styles.fullWidthButton]: fullWidth,
        })}
      >
        {button}
      </Link>
    );
  }
  return button;
}

/**
 * @typedef {Object} AdditionalLinkButtonProps
 * @prop {string} title
 * @prop {string} [className]
 * @prop {Boolean} [isContent]
 * @prop {Boolean} [fullWidth]
 * @prop {React.ReactNode} [icon]
 * @prop {Boolean} [reversed]
 * @prop {string} [to]
 *
 * @param {import("antd/lib/button").ButtonProps & AdditionalLinkButtonProps} props
 */
export function LinkButton({
  isContent,
  icon,
  title,
  className: customClassName,
  reversed = false,
  to,
  fullWidth,
  ...props
}) {
  const button = (
    <Button
      {...props}
      type="link"
      className={classNames(styles.button, styles.linkButton, {
        [styles.contentButton]: isContent,
        [styles.fullWidthButton]: fullWidth,
        [customClassName]: customClassName && !Boolean(to),
      })}
    >
      <div
        className={classNames(styles.textWithIconContainer, {
          [styles.reversed]: reversed,
        })}
      >
        {title}
        {icon}
      </div>
    </Button>
  );

  if (to) {
    return (
      <Link
        to={to}
        className={classNames({
          [customClassName]: customClassName && Boolean(to),
          [styles.fullWidthButton]: fullWidth,
        })}
      >
        {button}
      </Link>
    );
  }
  return button;
}
