import React from "react";
import Icon from "@ant-design/icons";

import ArrowForwardSvg from "assets/icons/arrow-forward.svg";
import BillingSvg from "assets/icons/billing.svg";
import CheckSvg from "assets/icons/check.svg";
import ChevronDownSvg from "assets/icons/chevron-down.svg";
import ChevronLeftSvg from "assets/icons/chevron-left.svg";
import ChevronRightSvg from "assets/icons/chevron-right.svg";
import CloseSvg from "assets/icons/close.svg";
import DocumentSvg from "assets/icons/document.svg";
import DownloadSvg from "assets/icons/download.svg";
import GlobeSvg from "assets/icons/globe.svg";
import GraphAreaSvg from "assets/icons/graph_area.svg";
import GraphLinesSvg from "assets/icons/graph-lines.svg";
import HistorySvg from "assets/icons/history.svg";
import LockedSvg from "assets/icons/locked.svg";
import MenuSvg from "assets/icons/menu.svg";
import MoreSvg from "assets/icons/more.svg";
import PinSvg from "assets/icons/pin.svg";
import QuoteMarksSvg from "assets/icons/quote-marks.svg";
import ResearchSvg from "assets/icons/research.svg";
import SeamlessSvg from "assets/icons/seamless.svg";
import SearchSvg from "assets/icons/search.svg";
import SettingsSvg from "assets/icons/settings.svg";
import StreamlineSvg from "assets/icons/streamline.svg";

const icons = {
  arrowForward: {
    label: "Arrow Forward",
    svg: ArrowForwardSvg,
  },
  billing: {
    label: "Billing",
    svg: BillingSvg,
  },
  check: {
    label: "Check",
    svg: CheckSvg,
  },
  chevronDown: {
    label: "Chevron Down",
    svg: ChevronDownSvg,
  },
  chevronLeft: {
    label: "Chevron Left",
    svg: ChevronLeftSvg,
  },
  chevronRight: {
    label: "Chevron Right",
    svg: ChevronRightSvg,
  },
  close: {
    label: "Close",
    svg: CloseSvg,
  },
  document: {
    label: "Document",
    svg: DocumentSvg,
  },
  download: {
    label: "Download",
    svg: DownloadSvg,
  },
  globe: {
    label: "Globe",
    svg: GlobeSvg,
  },
  graphArea: {
    label: "Graph Area",
    svg: GraphAreaSvg,
  },
  graphLines: {
    label: "Graph Lines",
    svg: GraphLinesSvg,
  },
  history: {
    label: "History",
    svg: HistorySvg,
  },
  locked: {
    label: "Locked",
    svg: LockedSvg,
  },
  menu: {
    label: "Menu",
    svg: MenuSvg,
  },
  more: {
    label: "More",
    svg: MoreSvg,
  },
  pin: {
    label: "Pin",
    svg: PinSvg,
  },
  quoteMarks: {
    label: "Quote Marks",
    svg: QuoteMarksSvg,
  },
  research: {
    label: "Research",
    svg: ResearchSvg,
  },
  seamless: {
    label: "Seamless",
    svg: SeamlessSvg,
  },
  search: {
    label: "Search",
    svg: SearchSvg,
  },
  settings: {
    label: "Settings",
    svg: SettingsSvg,
  },
  streamline: {
    label: "Streamline",
    svg: StreamlineSvg,
  },
};

export const iconOptions = Object.entries(icons).map(([key, { label }]) => ({
  label,
  value: key,
}));

/** @typedef {keyof icons} IconsAvailable  */

/**
 *
 * @typedef {object} SizeParam
 * @prop {number} [width]
 * @prop {number} [height]
 */

/**
 * @param {IconsAvailable} [id]
 * @param {SizeParam & Exclude<React.ComponentProps<Icon>, "component">} [options]
 **/
export default function getIcon(id, { width, height, ...iconProps } = {}) {
  const IconComponent = icons[id] && icons[id].svg;

  return (
    <Icon
      component={
        width || height
          ? () => <IconComponent width={width} height={height} />
          : IconComponent
      }
      {...iconProps}
    />
  );
}
