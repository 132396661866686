import React from "react";
import Img from "gatsby-image";

/**
 * @typedef {object} AdditionalProps
 * @prop {Image.ImageParam | string | React.ReactElement} [src]
 * @prop {string} [publicURL]
 * @prop {string} alt Force providing an alt
 * @prop {string} [className] Force className to be a string

 * @param {Image.GatsbyImageProps & AdditionalProps} props
 */
export default ({
  src: image,
  title,
  alt,
  className,
  style,
  publicURL,
  ...imageProps
}) => {
  /** If the CMS passed a prepared image element, use that */
  if (React.isValidElement(image)) {
    return image;
  }

  /** Props that are common to img and gatsby-image */
  const htmlProps = { className, style, title };

  if (typeof image === "string") {
    /** In this case, src was a URL */
    return <img src={image} alt={alt} {...htmlProps} />;
  }

  if (!image) {
    return <img src={publicURL} alt={alt} {...htmlProps} />;
  }

  if (image.fixed) {
    return <Img fixed={image.fixed} alt={alt} {...imageProps} {...htmlProps} />;
  } else {
    return <Img fluid={image.fluid} alt={alt} {...imageProps} {...htmlProps} />;
  }
};
